export const languageToCountryMap = {
  af: 'za', //South Africa
  am: 'et', //Ethiopia
  ar: 'ae', //United Arab Emirates
  az: 'az', //Azerbaijan
  be: 'by', //Belarus
  bg: 'bg', //Bulgaria
  bi: 'vu', //Vanuatu
  bn: 'bd', //Bangladesh
  bs: 'ba', //Bosnia and Herzegovina
  ca: 'ad', //Andorra
  cs: 'cz', //Czech Republic
  da: 'dk', //Denmark
  de: 'de', //Germany
  dv: 'mv', //Maldives
  dz: 'bt', //Bhutan
  el: 'gr', //Greece
  en: 'gb', //United Kingdom
  es: 'es', //Spain
  et: 'ee', //Estonia
  fa: 'ir', //Iran
  fi: 'fi', //Finland
  fo: 'fo', //Faroe Islands
  fr: 'fr', //France
  he: 'il', //Israel
  hi: 'in', //India
  hr: 'hr', //Croatia
  hu: 'hu', //Hungary
  hy: 'am', //Armenia
  id: 'id', //Indonesia
  is: 'is', //Iceland
  it: 'it', //Italy
  ja: 'jp', //Japan
  ka: 'ge', //Georgia
  kk: 'kz', //Kazakhstan
  kl: 'gl', //Greenland
  km: 'kh', //Cambodia
  ko: 'kr', //South Korea
  ky: 'kg', //Kyrgyzstan
  lb: 'lu', //Luxembourg
  lo: 'la', //Laos
  lt: 'lt', //Lithuania
  lv: 'lv', //Latvia
  mfe: 'mu', //Mauritius
  mg: 'mg', //Madagascar
  mi: 'nz', //New Zealand
  mk: 'mk', //North Macedonia
  mn: 'mn', //Mongolia
  ms: 'my', //Malaysia
  mt: 'mt', //Malta
  my: 'mm', //Myanmar
  na: 'nr', //Nauru
  nb: 'no', //Norway
  ne: 'np', //Nepal
  niu: 'nu', //Niue
  nl: 'nl', //The Netherlands
  nn: 'no', //Norway
  no: 'no', //Norway
  pau: 'pw', //Palau
  pih: 'pn', //Pitcairn
  pl: 'pl', //Poland
  ps: 'af', //Afghanistan
  pt: 'pt', //Portugal
  ro: 'ro', //Romania
  ru: 'ru', //Russia
  rw: 'rw', //Rwanda
  si: 'lk', //Sri Lanka
  sk: 'sk', //Slovakia
  sl: 'si', //Slovenia
  sm: 'ws', //Samoa
  so: 'so', //Somalia
  sq: 'al', //Albania
  sr: 'rs', //Serbia
  srp: 'me', //Montenegro
  ss: 'sz', //eSwatini
  sv: 'se', //Sweden
  sw: 'ke', //Kenya
  tg: 'tj', //Tajikistan
  th: 'th', //Thailand
  ti: 'er', //Eritrea
  tk: 'tm', //Turkmenistan
  tkl: 'tk', //Tokelau
  tr: 'tr', //Turkey
  uk: 'ua', //Ukraine
  ur: 'pk', //Pakistan
  uz: 'uz', //Uzbekistan
  vi: 'vn', //Vietnam
  'zh-hans': 'cn', //China
  'zh-hant': 'tw', //Taiwan
};
