export const scrollDownIntoView = (element) => {
    
    const elementTop = getOffsetTop(element);
    const scrollTop = document.documentElement.scrollTop;

    if (elementTop > scrollTop) {
        scrollTo(elementTop - scrollTop, 300);
    }

};

function getOffsetTop(elem) {
    var offsetTop = 0;
    do {
        if (!isNaN(elem.offsetTop)) {
            offsetTop += elem.offsetTop;
        }
    } while (elem = elem.offsetParent);
    return offsetTop;
}

function scrollTo(to, duration) {

    let start = document.documentElement.scrollTop,
        currentTime = 0,
        increment = 20;

    let animateScroll = function () {
        currentTime += increment;

        let val = easeInOutQuad(currentTime, start, to, duration);
        document.documentElement.scrollTop = val;

        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };

    animateScroll();
}
// Function for smooth scroll animation with the time duration
function easeInOutQuad(time, startPos, endPos, duration) {
    time /= duration / 2;

    if (time < 1) return (endPos / 2) * time * time + startPos;
    time--;
    return (-endPos / 2) * (time * (time - 2) - 1) + startPos;
}