<script>
import { scrollDownIntoView } from '../helpers/scroll-down-into-view.js';

export default {
  props: {
    targetElementSelector: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      showArrow: true,
    };
  },
  mounted() {
    this.changeArrowVisibilityBasedOnScrollPosition();
    window.addEventListener(
      'scroll',
      this.changeArrowVisibilityBasedOnScrollPosition
    );
  },
  beforeUnmount() {
    window.removeEventListener(
      'scroll',
      this.changeArrowVisibilityBasedOnScrollPosition
    );
  },
  methods: {
    changeArrowVisibilityBasedOnScrollPosition() {
      const el = this.$refs.img;
      const parentContainerBottom =
        el.parentNode.getBoundingClientRect()?.bottom || 0;

      if (parentContainerBottom === 0) {
        this.showArrow = false;
        return;
      }

      const ratio = parentContainerBottom / window.innerHeight;

      el.style.opacity = ratio > 0.5 ? '1' : '0';

      el.style.pointerEvents = ratio > 0.5 ? 'auto' : 'none';

      el.parentNode.style.transform = ratio <= 1 ? 'translateZ(0)' : '';

      el.style.top =
        ratio <= 1 ? 'calc(100% - 166px)' : 'calc(100vh - 166px)';
    },
    scrollToTargetElementSelector() {
      const target = document.querySelector(this.targetElementSelector);
      scrollDownIntoView(target);
    },
  },
};
</script>

<template>
  <img
    ref="img"
    class="scroll-down-to-content jsFloatingLink"
    @click="scrollToTargetElementSelector"
    src="/icons/icon_chevron_down.svg"
  />
</template>

<style>
.scroll-down-to-content {
  position: fixed;
  z-index: 20;
  width: 62px;
  height: 46px;
  left: calc(50% - 31px);
  top: calc(100vh - 166px);
  cursor: pointer;
  display: inline-block;
  transition: opacity 0.2s linear;
}
</style>
