let scrollPosition = 0;

export default {
  enable() {
    if (typeof window === 'undefined') return;
    const body = document.querySelector('body');
    scrollPosition = window.pageYOffset;
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.top = `-${scrollPosition}px`;
    body.style.width = '100%';

    if (!window.innerWidth < 768) {
      const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
      body.style.paddingRight = `${scrollbarWidth.toString()}px`;
    }
  },
  disable() {
    if (typeof window === 'undefined') return;
    const body = document.querySelector('body');
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');
    body.style.paddingRight = '0';
    window.scrollTo(0, scrollPosition);
  },
};
