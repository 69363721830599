/* eslint-disable one-var */
export const scrollIntoView = (parent, child, isHorizontal = false, padding = 0) => {

  const parentBounding = parent.getBoundingClientRect(),
    clientBounding = child.getBoundingClientRect();

  const directionStart = isHorizontal ? 'left' : 'top',
    directionEnd = isHorizontal ? 'right' : 'bottom';

  const parentEnd = parentBounding[directionEnd],
    parentStart = parentBounding[directionStart],
    clientEnd = clientBounding[directionEnd],
    clientStart = clientBounding[directionStart];

  if (parentStart >= clientStart) {
    scrollTo(parent, -(parentStart - clientStart) - padding, 300, isHorizontal);
  } else if (clientEnd > parentEnd) {
    scrollTo(parent, clientEnd - parentEnd + padding, 300, isHorizontal);
  }
};

function scrollTo(element, to, duration, isHorizontal) {
  const scrollDirection = isHorizontal ? 'scrollLeft' : 'scrollTop';

  let start = element[scrollDirection],
    currentTime = 0,
    increment = 20;

  let animateScroll = function() {
    currentTime += increment;

    let val = easeInOutQuad(currentTime, start, to, duration);
    element[scrollDirection] = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  animateScroll();
}
// Function for smooth scroll animation with the time duration
function easeInOutQuad(time, startPos, endPos, duration) {
  time /= duration / 2;

  if (time < 1) return (endPos / 2) * time * time + startPos;
  time--;

  return (-endPos / 2) * (time * (time - 2) - 1) + startPos;
}
