import {
  computed,
  inject,
  onMounted,
  provide,
  ref,
  shallowRef,
  watch,
} from 'vue';

import { GraphQLClient, gql } from 'graphql-request';

import { channelKey } from '@/keys';

import useWebsiteText from '@/composables/useWebsiteTexts';
import useSizeDisplay from './useSizeDisplay';
import useContext from './useContext';

export default function () {
  const tyckaUrl = `https://api.tycka.io/${process.env.TYCKA_ACCOUNT_ID}/graphql`;

  const gqlClient = new GraphQLClient(tyckaUrl);
  const initialNumberOfReviews = 3;
  const numberOfReviews = 10;

  const channel = inject(channelKey);

  const { page } = useContext();
  const product = computed(() => page?.value.dataJson?.product);

  const reviews = ref<Hit[]>([]);
  provide('reviews', reviews);

  const numberOfHits = ref(0);
  provide('numberOfReviewHits', numberOfHits);

  const facets = ref<Facets>({} as Facets);
  provide('reviewFacets', facets);

  const isSortFacetSelected = ref(false);
  provide('isReviewSortFacetSelected', isSortFacetSelected);

  const take = ref(initialNumberOfReviews);
  const skip = ref(0);
  const sort = ref<SortOrderKeys>('createdAt_desc');
  provide('reviewSortingMethod', sort);

  const selectedFacets = shallowRef<FacetsToSend>({} as FacetsToSend);
  provide('selectedReviewFacets', selectedFacets);

  const reviewsRatingAverage = ref(0);
  provide('reviewsRatingAverage', reviewsRatingAverage);

  const total = ref(0);

  const totalReviews = computed(
    () =>
      facets.value.productRating.reduce((acc, curr) => acc + curr.count, 0) || 0
  );
  provide('totalReviews', totalReviews);

  const errors = ref<string[]>([]);

  const requestPending = ref(false);

  const ratingDistribution = computed<Map<number, number>>(() => {
    const newDistribution: Map<number, number> = new Map([
      [5, 0],
      [4, 0],
      [3, 0],
      [2, 0],
      [1, 0],
    ]);
    facets.value.productRating.forEach((rating) => {
      newDistribution.set(
        Number((parseFloat(rating.value) * 5).toFixed(1)),
        rating.count
      );
    });
    return newDistribution;
  });
  provide('ratingDistribution', ratingDistribution);

  const canFetchMoreReviews = computed(
    () => !requestPending.value && total.value > reviews.value.length
  );
  provide('canFetchMoreReviews', canFetchMoreReviews);

  const language = computed<string>(() => channel?.value.locale.split('-')[0]);

  const colorsHue = computed<{ value: string; name: string }[]>(
    () => channel?.value.colorsHue
  );
  provide('colorsHue', colorsHue);

  const canDisplayReviews = computed(
    () => total.value > 0 && !errors.value.length
  );
  provide('canDisplayReviews', canDisplayReviews);

  watch([skip, selectedFacets, sort], async () => await fetchReviews());
  watch(
    () => product.value?.fields._name,
    async () => {
      resetDataOnSPANavigation();
      await fetchReviews();
    }
  );

  onMounted(async () => {
    await fetchReviews();
  });

  provide('addReviewFacet', (key: FacetKeys, value: string) => {
    if (!(key in selectedFacets.value)) {
      selectedFacets.value[key] = [];
    }

    resetReviews();

    selectedFacets.value[key].push(value);
    selectedFacets.value = { ...selectedFacets.value };
  });

  provide('removeReviewFacet', (key: FacetKeys, value: string) => {
    if (key in selectedFacets.value) {
      selectedFacets.value[key] = selectedFacets.value[key].filter(
        (facetValue) => facetValue !== value
      );
      if (!selectedFacets.value[key].length) delete selectedFacets.value[key];

      resetReviews();

      selectedFacets.value = {
        ...selectedFacets.value,
      };
    }
  });

  provide('removeReviewFacetValues', (key: FacetKeys) => {
    if (selectedFacets.value[key]) {
      delete selectedFacets.value[key];

      resetReviews();

      selectedFacets.value = { ...selectedFacets.value };
    }
  });

  provide('setSortingOption', (key: SortOrderKeys) => {
    resetReviews();
    sort.value = key;
  });

  provide('clearReviewFacets', () => {
    resetReviews();
    selectedFacets.value = {} as FacetsToSend;
  });

  provide('fetchMoreReviews', () => {
    if (canFetchMoreReviews.value) {
      if (take.value === initialNumberOfReviews) {
        take.value = numberOfReviews;
        skip.value += initialNumberOfReviews;
        return;
      }

      skip.value = skip.value + numberOfReviews;
    }
  });

  function resetReviews() {
    reviews.value = [];
    skip.value = 0;
  }

  function resetDataOnSPANavigation() {
    take.value = initialNumberOfReviews;
    skip.value = 0;
    sort.value = 'createdAt_desc';
    isSortFacetSelected.value = false;
    reviews.value = [];
    numberOfHits.value = 0;
    reviewsRatingAverage.value = 0;
    facets.value = {} as Facets;
    selectedFacets.value = {} as FacetsToSend;
    total.value = 0;
    errors.value = [];
    requestPending.value = false;
  }

  async function fetchReviews() {
    if (requestPending.value) return;
    requestPending.value = true;
    try {
      const res = await gqlClient.request<QueryResponse>(
        reviewQueryBuilder({
          sortOrder: sort.value,
          facets: selectedFacets.value,
          take: take.value,
          skip: skip.value,
          language: language.value,
          filter: {
            item_productFamily: product.value.fields._name,
          },
        })
      );

      reviews.value = [...reviews.value, ...res.publicReviews.hits];
      numberOfHits.value = res.publicReviews.hits.length;
      reviewsRatingAverage.value = parseFloat(
        (res.publicReviews.average * 5).toFixed(1)
      );
      facets.value = res.publicReviews.facets;
      total.value = res.publicReviews.total;
    } catch (error: any) {
      errors.value = error?.response?.errors;
    }
    requestPending.value = false;
  }
}

export function getReviewsContext() {
  const { websiteText } = useWebsiteText();
  const { getSizeName } = useSizeDisplay();

  const sortOptions = computed(() => [
    {
      label: websiteText('review_filterdateasc').value,
      value: 'createdAt_asc',
    },
    {
      label: websiteText('review_filterdatedesc').value,
      value: 'createdAt_desc',
    },
    {
      label: websiteText('review_filterratingasc').value,
      value: 'rating_asc',
    },
    {
      label: websiteText('review_filterratingdesc').value,
      value: 'rating_desc',
    },
  ]);

  const colorHue = inject(
    'colorsHue',
    computed<{ value: string; name: string }[]>(() => [])
  );

  function parseReviewRating(rating: number) {
    return parseFloat((rating * 5).toFixed(1));
  }

  function facetDisplayFormatter(key: FacetKeys, value: string | number) {
    switch (key) {
      case 'item_color': {
        return colorHue?.value?.find((c) => c.value === value)?.name || value;
      }
      case 'item_size': {
        return getSizeName(value.toString());
      }
      case 'language': {
        return websiteText(`review_language${value}`).value || value;
      }
      case 'productRating': {
        return parseReviewRating(value as number);
      }
    }
  }

  return {
    reviews: inject('reviews', ref<Hit[]>([])),
    totalReviews: inject(
      'totalReviews',
      computed(() => 0)
    ),
    reviewsRatingAverage: inject('reviewsRatingAverage', ref(0)),
    selectedFacets: inject(
      'selectedReviewFacets',
      shallowRef<FacetsToSend>({} as FacetsToSend)
    ),
    facets: inject('reviewFacets', ref<Facets>({} as Facets)),
    canFetchMoreReviews: inject(
      'canFetchMoreReviews',
      computed(() => false)
    ),
    numberOfHits: inject('numberOfReviewHits', ref(0)),
    sortingMethod: inject(
      'reviewSortingMethod',
      ref<SortOrderKeys>('createdAt_desc')
    ),
    isSortFacetSelected: inject('isReviewSortFacetSelected', ref(false)),
    sortOptions: sortOptions,
    colorsHue: colorHue,
    canDisplayReviews: inject(
      'canDisplayReviews',
      computed(() => false)
    ),
    ratingDistribution: inject(
      'ratingDistribution',
      computed(() => new Map())
    ),
    addFacet: inject('addReviewFacet', (_key: FacetKeys, _value: string) => {}),
    removeFacet: inject(
      'removeReviewFacet',
      (_key: FacetKeys, _value: string) => {}
    ),
    removeFacetValues: inject(
      'removeReviewFacetValues',
      (_key: FacetKeys) => {}
    ),
    clearFacets: inject('clearReviewFacets', () => {}),
    fetchMoreReviews: inject('fetchMoreReviews', () => {}),
    setSortingOption: inject('setSortingOption', (_key: SortOrderKeys) => {}),
    parseReviewRating: parseReviewRating,
    facetDisplayFormatter: facetDisplayFormatter,
  };
}

function reviewQueryBuilder(args: QueryBuilderArgs) {
  return gql`
    query {
      publicReviews(
        filter: {
          item_productFamily: ${JSON.stringify(args.filter?.item_productFamily)}
        }
        facets:  ${constructFacets(args?.facets)} 
        take: ${args?.take || 10}
        skip: ${args?.skip || 0}
        sort: {
            order: [${args?.sortOrder || ''}]
            config: ${JSON.stringify(args.sortConfig || {}).replace(
              /"/g,
              '\\"'
            )}
        }
      ) {
        average
        breakdown {
          value
          count
        }
        facets {
          productRating {
            value
            count
          }
          language {
            value
            count
          }
          item_color {
            value
            count
          }
          item_size {
            value
            count
          }
        }
        hits {
          publishedAt
          createdAt
          id
          rating
          verified
          language
          item {
            baseProduct
            color
            id
            imageUrls
            model
            name
            productFamily
            size
          }
          productRating {
            value
            comment
          }
          author {
            name
          }
          response {
            name
            content
          }
          translation(language: "${args.language}") {
            productRating
          }
        }
        total
      }
    }
  `;
}

function constructFacets(facets?: FacetsToSend) {
  if (!facets) return {};

  const facetEntries = Object.entries(facets).map(([key, values]) => {
    const valueList = values.map((value) => `"${value}"`).join(', ');
    return `${key}: [${valueList}]`;
  });
  return `{ ${facetEntries.join(' ')} }`;
}

type QueryBuilderArgs = {
  itemId?: string;
  take?: number;
  skip?: number;
  filter?: Filter;
  facets?: FacetsToSend;
  sortOrder?: string;
  sortConfig?: object;
  language?: string;
};

type QueryResponse = {
  publicReviews: {
    average: number;
    breakdown: {
      value: string;
      count: number;
    }[];
    facets: Facets;
    hits: Hit[];
    total: number;
  };
};

export type FacetInformation = {
  value: string;
  count: number;
};

export type Hit = {
  author: {
    name: string;
  };
  productRating: {
    value: number;
    comment: string;
  };
  id: string;
  item: {
    baseProduct: string;
    color: string;
    id: string;
    imageUrls: string[];
    model: string;
    name: string;
    productFamily: string;
    size: string;
  };
  language: string;
  publishedAt: string;
  createdAt: string;
  rating: number;
  response: {
    name: string;
    content: string;
  };
  verified: boolean;
  translation: {
    productRating: string;
  };
};

export type FacetKeys =
  | 'productRating'
  | 'language'
  | 'item_color'
  | 'item_size';

export type Facets = { [key in FacetKeys]: FacetInformation[] };

export type FacetsToSend = { [key in FacetKeys]: string[] };

export type Filter = {
  item_productFamily: string;
};

export type SortOrderKeys =
  | 'language_asc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'rating_asc'
  | 'rating_desc';
