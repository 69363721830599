<script>
export default {
  props: {
    unzoomedImageUrl: {
      required: true,
      type: String,
    },
    zoomedImageUrl: {
      required: false,
      type: String,
    },
    fixedHeight: {
      required: false,
      type: Number,
      default: 0,
    },
    maxZoomLevel: {
      required: false,
      type: Number,
      default: 2.5,
    },
  },
  data() {
    return {
      image: null,
      highResolutionImage: null,
      imageHeight: 0,
      imageWidth: 0,
      height: 0,
      width: 0,
      zoomLocationX: 0,
      zoomLocationY: 0,
      spacingTop: 0,
      spacingLeft: 0,
      highResolutionImageLoaded: false,
      loadingHighResolutionImage: false,
      zoomLevel: 1,
      startScale: 0,
      startZoom: 1,
      startLocationX: 0,
      startLocationY: 0,
      startTranslateLocationX: 0,
      startTranslateLocationY: 0,
      lastPanPosition: null,
      hammerManager: null,
      disablePan: false,
    };
  },
  mounted() {
    const Hammer = require('hammerjs');

    window.addEventListener('resize', this.calculateSize);
    window.addEventListener('scroll', this.onScroll);

    this.image = new Image();
    this.image.addEventListener('load', this.loadImage);
    this.image.src = this.unzoomedImageUrl;

    this.$nextTick(() => {
      this.hammerManager = new Hammer(this.$refs.container, {
        touchAction: 'auto',
        domEvents: true,
        inputClass: Hammer.TouchInput,
      });
      this.hammerManager.get('pinch').set({ enable: true, threshold: 0.05 });
      this.hammerManager
        .get('pan')
        .set({ enable: true, pointers: 0, threshold: 5 });
      this.hammerManager
        .get('doubletap')
        .set({ interval: 500, posThreshold: 20 });

      this.hammerManager.on('doubletap', this.onDoubleTap);
      this.hammerManager.on('pinchout', this.onPinchOut);
      this.hammerManager.on('pinchin', this.onPinchIn);
      this.hammerManager.on('pinchend', this.onPinchEnd);
      this.hammerManager.on('pan', this.onPan);
      this.hammerManager.on('panend', this.onPanEnd);
    });
  },
  beforeUnmount() {
    if (this.hammerManager) {
      this.hammerManager.off('doubletap pinchout pinchin pinchend');
    }

    window.removeEventListener('resize', this.calculateSize);
    window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    normalizedZoomLevel() {
      if (this.zoomLevel < 1) return 1;
      if (this.zoomLevel > this.maxZoomLevel) return this.maxZoomLevel;
      return this.zoomLevel;
    },
    style() {
      return this.imageWidth !== 0 && this.$refs.container
        ? {
          backgroundImage: `url(${
            this.highResolutionImageLoaded
              ? this.zoomedImageUrl
              : this.unzoomedImageUrl
          })`,
          width: '100%',
          height: `${this.height}px`,
          transform: this.isZoomed
            ? `scale(${this.normalizedZoomLevel}) translateX(${this.zoomLocationX}%) translateY(${this.zoomLocationY}%)`
            : '',
        }
        : {};
    },
    containerStyle() {
      if (this.fixedHeight) {
        return { height: `${this.fixedHeight}px` };
      }
      return '';
    },
    screenImageWidth() {
      return (this.height * this.imageWidth) / this.imageHeight;
    },
    percentageZoomLocation() {
      return this.getPercentageLocationFromTranslateLocation(
        this.zoomLocationX,
        this.zoomLocationY
      );
    },
    isZoomed() {
      return this.zoomLevel !== 1;
    },
  },
  watch: {
    isZoomed(newValue) {
      if (!newValue) {
        this.zoomLocationX = 0;
        this.zoomLocationY = 0;
        this.startLocationX = 0;
        this.startLocationY = 0;
        this.startScale = 0;

        if (this.$refs.container) {
          this.$refs.container.removeEventListener(
            'touchstart',
            this.preventScrolling
          );
        }
      } else {
        this.loadHighResolutionImage();
        if (this.$refs.container) {
          this.$refs.container.removeEventListener(
            'touchstart',
            this.preventScrolling
          );
          this.$refs.container.addEventListener(
            'touchstart',
            this.preventScrolling
          );
        }
      }
    },
    fixedHeight() {
      this.calculateSize();
    },
    zoomedImageUrl(newUrl) {
      if (newUrl) {
        this.highResolutionImageLoaded = false;
      } else {
        this.highResolutionImageLoaded = false;
      }
    },
  },
  methods: {
    getPercentageLocation(x, y) {
      const offsetX = (this.width - this.screenImageWidth) / 2;

      return {
        x: ((x - offsetX - this.spacingLeft) / this.screenImageWidth) * 100,
        y: ((y - this.spacingTop) / this.height) * 100,
      };
    },
    getTranslateLocation(x, y) {
      const percentageLocation = this.getPercentageLocation(x, y);

      if (percentageLocation.x < 0) {
        percentageLocation.x = 0;
      } else if (percentageLocation.x > 100) {
        percentageLocation.x = 100;
      }
      if (percentageLocation.y < 0) {
        percentageLocation.y = 0;
      } else if (percentageLocation.y > 100) {
        percentageLocation.y = 100;
      }

      return {
        x:
          (percentageLocation.x - 50) *
          (this.screenImageWidth / this.width) *
          -1,
        y: (percentageLocation.y - 50) * -1,
      };
    },
    getPercentageLocationFromTranslateLocation(x, y) {
      return {
        x: x / (this.screenImageWidth / this.width) / -1 + 50,
        y: y / -1 + 50,
      };
    },
    zoomToRelativeLocation(x, y) {
      const translateLocation = this.getTranslateLocation(x, y);

      this.zoomLocationX =
        translateLocation.x - translateLocation.x / this.maxZoomLevel;
      this.zoomLocationY =
        translateLocation.y - translateLocation.y / this.maxZoomLevel;
      return true;
    },
    zoomToPinchLocation() {
      if (this.startLocationX === null || this.startLocationX === null) return;

      this.zoomLocationX =
        this.startLocationX -
        this.startTranslateLocationX / this.normalizedZoomLevel;
      this.zoomLocationY =
        this.startLocationY -
        this.startTranslateLocationY / this.normalizedZoomLevel;
    },
    setupPinchLocation(e) {
      const translateLocation = this.getTranslateLocation(
        e.center.x,
        e.center.y
      );
      this.startScale = e.scale;
      this.startZoom = this.normalizedZoomLevel;

      if (!translateLocation) {
        this.startLocationX = null;
        this.startLocationY = null;
      } else {
        this.startTranslateLocationX = translateLocation.x;
        this.startTranslateLocationY = translateLocation.y;
        this.startLocationX =
          this.zoomLocationX + translateLocation.x / this.normalizedZoomLevel;
        this.startLocationY =
          this.zoomLocationY + translateLocation.y / this.normalizedZoomLevel;
      }
    },
    toggleZoom(x, y) {
      if (this.isZoomed) {
        this.zoomLevel = 1;
      } else {
        this.zoomToRelativeLocation(x, y);
        this.zoomLevel = this.maxZoomLevel;
      }
    },
    resetZoom() {
      if (!this.isZoomed) return;

      this.zoomLevel = 1;
    },
    loadImage(e) {
      this.imageWidth = this.image.naturalWidth;
      this.imageHeight = this.image.naturalHeight;

      this.calculateSize();
      this.onScroll(null);

      this.image.removeEventListener('load', this.loadImage);
      this.image = null;
    },
    loadHighResolutionImage() {
      if (
        this.zoomedImageUrl &&
        !this.loadingHighResolutionImage &&
        !this.highResolutionImageLoaded
      ) {
        this.highResolutionImage = new Image();
        this.loadingHighResolutionImage = false;
        this.highResolutionImage.addEventListener(
          'load',
          this.setHighResolutionImage
        );
        this.highResolutionImage.src = this.zoomedImageUrl;
      }
    },
    setHighResolutionImage() {
      this.imageWidth = this.highResolutionImage.naturalWidth;
      this.imageHeight = this.highResolutionImage.naturalHeight;
      this.highResolutionImageLoaded = true;
      this.loadingHighResolutionImage = false;
      this.calculateSize();
      this.onScroll(null);

      this.highResolutionImage.removeEventListener(
        'load',
        this.setHighResolutionImage
      );
      this.highResolutionImage = null;
    },
    preventScrolling(e) {
      if (typeof e.cancelable !== 'boolean' || e.cancelable) {
        e.preventDefault();
      }
    },
    onDoubleTap(e) {
      this.updateSpacing();
      this.toggleZoom(e.pointers[0].clientX, e.pointers[0].clientY);
    },
    onPinchOut(e) {
      e.srcEvent.preventDefault();
      e.srcEvent.stopPropagation();

      if (!this.startScale) {
        this.updateSpacing();
        this.setupPinchLocation(e);
      }

      this.zoomLevel = this.startZoom + e.scale - this.startScale;
      this.zoomToPinchLocation();
    },
    onPinchIn(e) {
      e.srcEvent.preventDefault();
      e.srcEvent.stopPropagation();

      if (!this.startScale) {
        this.setupPinchLocation(e);
      }

      this.zoomLevel =
        this.startZoom - (this.startScale - e.scale) * this.maxZoomLevel * 2;
      this.zoomToPinchLocation();
    },
    onPinchEnd(e) {
      e.srcEvent.stopPropagation();
      this.startScale = 0;
    },
    onPan(e) {
      if (this.isZoomed) {
        if (
          typeof e.srcEvent.cancelable !== 'boolean' ||
          e.srcEvent.cancelable
        ) {
          e.srcEvent.preventDefault();
        }

        const panPosition = this.getPercentageLocation(e.center.x, e.center.y);
        if (!this.lastPanPosition) {
          this.lastPanPosition = panPosition;
        }

        const newLocationX =
          this.zoomLocationX +
          (panPosition.x - this.lastPanPosition.x) / this.normalizedZoomLevel;
        const newLocationY =
          this.zoomLocationY +
          (panPosition.y - this.lastPanPosition.y) / this.normalizedZoomLevel;
        const percentageLocation =
          this.getPercentageLocationFromTranslateLocation(
            newLocationX,
            newLocationY
          );

        if (
          percentageLocation.x >= 50 / this.normalizedZoomLevel &&
          percentageLocation.x <= 100 - 50 / this.normalizedZoomLevel &&
          percentageLocation.y >= 50 / this.normalizedZoomLevel &&
          percentageLocation.y <= 100 - 50 / this.normalizedZoomLevel
        ) {
          e.srcEvent.stopPropagation();
          this.zoomLocationX = newLocationX;
          this.zoomLocationY = newLocationY;
          this.lastPanPosition = panPosition;
        } else if (!this.disablePan) {
          e.srcEvent.stopPropagation();
        }
      }
    },
    onPanEnd(e) {
      if (
        this.isZoomed &&
        !(
          this.disablePan &&
          (this.percentageZoomLocation.x < 53 / this.normalizedZoomLevel ||
            this.percentageZoomLocation.x > 97 - 50 / this.normalizedZoomLevel)
        )
      ) {
        e.srcEvent.stopPropagation();
      }

      this.lastPanPosition = null;
      this.disablePan =
        this.percentageZoomLocation.x < 53 / this.normalizedZoomLevel ||
        this.percentageZoomLocation.x > 97 - 50 / this.normalizedZoomLevel;
    },

    calculateSize() {
      if (this.imageWidth === 0 || !this.$refs.container) return;

      const container = this.$refs.container.getBoundingClientRect();

      this.width = container.width;
      this.height = this.fixedHeight || container.height;
    },
    onScroll(e) {
      this.updateSpacing();
    },
    updateSpacing() {
      if (!this.$refs.container) return;

      const container = this.$refs.container.getBoundingClientRect();
      this.spacingTop = container.top;
    },
  },
};
</script>

<template>
  <div>
    <div
      class="zoomable-image__container"
      ref="container"
      :style="containerStyle"
    >
      <div
        class="zoomable-image"
        :class="{ 'zoomable-image--zoomed': normalizedZoomLevel > 1 }"
        :style="style"
      ></div>
    </div>
  </div>
</template>

<style>
.zoomable-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: white;
}
.zoomable-image__button {
  transform: scaleX(-1) translateY(-100%);
  position: absolute;
  background-color: hsla(0, 0%, 100%, 0.5);
  padding: 0.2rem;
  border-radius: 7px;
  right: 0.5rem;
  bottom: 0rem;
}
.zoomable-image__button-icon {
  display: block;
  width: 1.5rem;
}
</style>
